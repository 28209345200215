<template>
    <div class="service-pop-group">
        <div class="service-small-pop" @click="showBigPop">
            <div class="tit-group">
                <i class="icon"></i>
                <span class="text">在线咨询</span>
            </div>
            <div class="code-img">
                <img src="../../assets/image/home/code_icon_small.png" alt="">
            </div>
        </div>
        <div class="service-big-pop" v-if="showBigCode">
            <p class="pop-tit">添加客服了解更多详情</p>
            <div class="close-btn" @click="closeBigPop">
                <img src="../../assets/image/main-page/close_icon.png" alt="">
            </div>
            <div class="code-img">
                <img src="../../assets/image/home/code_icon_big.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "service-pop",
        data() {
            return {
                showBigCode: false
            }
        },
        methods: {
            //显示大图弹窗
            showBigPop(){
                this.showBigCode = true;
            },
            //关闭大图弹窗
            closeBigPop(){
                this.showBigCode = false;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/base";
    .service-small-pop{
        position: fixed;
        z-index: 9;
        bottom: 8%;
        right: 10px;
        cursor: pointer;
        .tit-group{
            @include flex-center;
            width: 255px;
            height: 98px;
            background-color: $blueColor;
            .icon{
                width: 47px;
                height: 41px;
                background: url("../../assets/image/home/contact_icon.png") no-repeat;
                background-size: contain;
            }
            .text{
                font-size: 36px;
                color: #fff;
                font-weight: 700;
                margin-left: 14px;

            }
        }
        .code-img{
            border: 2px solid $blueColor;
            text-align: center;
            background-color: #fff;
            padding: 15px 0 17px;
            img{
                width: 217px;
                height: 217px;
            }
        }
    }
    .service-big-pop{
        position: fixed;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .pop-tit{
            width: 600px;
            height: 144px;
            font-size: 28px;
            color: #fff;
            background-color: $blueColor;
            line-height: 144px;
            text-align: center;
            border-radius: 10px 10px 0 0;
        }
        .code-img{
            border: 2px solid $blueColor;
            border-radius: 0 0 10px 10px;
            text-align: center;
            padding: 38px 0 48px;
            background-color: #fff;
            img{
                width: 501px;
                height: 501px;
            }
        }
        .close-btn{
            width: 20px;
            height: 20px;
            position: absolute;
            top: 18px;
            right: 18px;
            padding: 10px;
            cursor: pointer;
            &:hover{
                opacity: .85;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
