<template>
    <div class="home-page">
        <div class="banner-group">
            <el-carousel
                height="641px"
                arrow="never"
            >
                <el-carousel-item>
                    <div class="banner-single">
                        <img src="../../assets/image/home/home_banner1.jpg" alt="">
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="banner-single">
                        <img src="../../assets/image/home/home_banner2.jpg" alt="">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
<!--        在线咨询浮窗-->
        <service-pop></service-pop>
<!--        产品中心-->
        <product-module></product-module>
<!--        功能特色-->
        <function-module></function-module>
<!--        入驻商家-->
        <client-module></client-module>
    </div>
</template>

<script>
    import ProductModule from "../page-module/product-module"
    import FunctionModule from "../page-module/function-module"
    import ClientModule from "../page-module/client-module"
    import ServicePop from "./service-pop"
    export default {
        name: "home",
        components: {
            ProductModule,
            FunctionModule,
            ClientModule,
            ServicePop
        }
    }
</script>

<style lang="scss">
    .banner-group{
        min-width: 1200px;
    }
    .banner-single{
        width: 1920px;
        height: 641px;
        margin-left: 50%;
        transform: translate(-50%, 0);
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>
